import * as React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Menu from "../components/header/Menu";
import { theme } from "../components/utilis/colors";
import Footer from "../components/utilis/Footer";
import { getTags } from "../components/utilis/ItemProjects";
import Layout from "../components/utilis/Layout";
import PostTitle from "../components/utilis/PostTitle";
import Section from "../components/utilis/Section";
import flavicon from "../assets/icons/lf_flav.png";

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #020202;
  background-image: url(${(p) => p.bgImg});
  background-size: cover;
  background-position: center;
`;
const BlogPostContent = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  p {
    font-size: 1.4rem;
    &.has-text-align-center {
      text-align: center;
    }
  }
  h2 {
    padding-top: 0.8rem;
  }
  pre {
    background-color: #222222;
    padding: 0.8rem;
    display: block;
    font-size: 1.2rem;
    overflow: auto;
  }
  a {
    color: ${theme.action};
  }
  figure {
  }
  picture {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  img {
    text-align: center;
    width: 100%;
    max-width: 90rem;
  }
`;

const ProjectPost = ({ pageContext }) => {
  const { post } = pageContext;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} />
        <link rel="canonical" href="https://lensflare.pl/" />
        <link
          rel="icon"
          href={flavicon}
          sizes="192x192"
        />
      </Helmet>
      <HeaderWrapper bgImg={post.featuredImage.node.sourceUrl}>
        <Menu alone />
        <PostTitle title={post.title} />
      </HeaderWrapper>
      <Section bgColor="#2d3a51" textColor={theme.text}>
        <BlogPostContent>
          <div>Used technology: {getTags(post.tags.nodes)}</div>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </BlogPostContent>
      </Section>
      <Footer />
    </Layout>
  );
};
export default ProjectPost;
